@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

.App {
  overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  background: #71cbfa;
  color: #000;
}

a {
  cursor: pointer;
  color: inherit;
}

img {
  width: 100%;
}

.box-shadow {
  box-shadow: 2px 2px 0px 0px #000;
}

.box-shadow-2 {
  box-shadow: 4px 4px 0px 0px #000;
}
